.logos-grid {
	@extend .container-max;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
}

.logos-grid_logo {
	@extend .text-center;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 210px;
	height: 210px;
	margin: 1.5%;
	border: solid 1% white;
	background-color: white;
	position: relative;
	overflow: hidden;
	box-shadow: $box-shadow;
	
	>a {
		display: block;
		transition: transform ease .5s;

		&:hover {
			transform: scale(1.1);
		}
	}

	img {
		@extend .img-responsive;
	}
	
	.logo_caption {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 5%;
		font-size: 1.4rem;
		font-weight: 700;
		background-color: rgba(black, .6);
		color: $gray-lighter;
	}
}