/* ==========================================================================
Accueil
========================================================================== */
body {
border-top: solid 5px $brand-second;
// background: darken($text-color, 30%);
overflow-x: hidden;
// background-color: $text-color;
// background-size: cover;
// background: url(../images/homeBG.jpg) top center no-repeat #d7d6cd;
background: url(../images/homeBG.jpg) top center no-repeat fixed #fff;

}
.body-accueil {
// padding-top: 5vh;
// border-top: solid 15px $brand-primary;
// @media (max-width: $screen-md) {
//   background: url(../images/homeBG-small.jpg) top center no-repeat #fff;
// }
// background-size: cover;
//   background: url(../images/bg-repeat.jpg) top center #9ad3d9;
//   // padding-top: 20px;
//     @media (min-width: $screen-sm-min) {
  background: url(../images/accueilBG.jpg) top center no-repeat #fff;
// }
}
.header-accueil {
@extend .animated;
@extend .fadeInDown;
@extend .text-center;
// @extend .container;
// height: 50rem;
// background-color: rgba(lighten($brand-primary, 5%), .5);
// background-color: rgba($brand-primary, .35);
background-color: rgba($body-bg, .5);
// background-color: $body-bg;
// background-color: white;
// background-color: $brand-primary;
// background: linear-gradient(rgba($body-bg, 0.5), rgba($body-bg, 0));;
// background: linear-gradient(rgba(#fff, 0.99), rgba(#fff, 0.7));;
// background: url(../images/accueilBG.png) top center no-repeat;
// background: url(../images/charte/outa-blanc.png) top center repeat-x;
box-shadow: $box-shadow;
overflow: hidden;
padding: 1rem 0;
// margin: 0 auto 10vh auto;
// margin: 5vh auto;
// border-top: solid 1rem $brand-second;
border-bottom: solid 3px $brand-primary;
// animation-delay: .3s;
// text-shadow: 1px 5px 10px black;
}
.card {
margin-top: 20px;
}
.card-accueil {
  @extend .card;
padding: 20px 20px 30px 20px !important;
  li {
  margin-bottom: 5px !important;
}
}
// .logow {
//   @extend .text-left;
//   @extend .h1;
//   color: $brand-primary;
//   font-size: 6rem;
//   border-bottom: solid .2rem $brand-primary;
//   padding-bottom: 15px;
//   margin-bottom: 15px;
// }
// .baseline {
//   @extend .text-left;
//   @extend .h1;
//   margin-top: -2rem; 
//   color: darken($brand-second, 20%);
// }

.logo-accueil {
// @extend .animated;
// @extend .fadeInDown;
@extend .img-responsive;
@extend .center-block;
@extend .text-center;
animation-delay: .4s;
display: inline-block;
// margin-top: 20px;
// margin: 2vh auto;
// padding: 20px 0;
// background-color: $brand-primary;
// border:solid 3px $brand-primary;

>img {
// box-shadow: $box-shadow;
max-height: 160px;
@extend .img-responsive;
@extend .center-block;
// display: inline-block;
// width: 50rem;
// border-bottom: solid 1px $brand-second;
margin-bottom: 1rem;
}
}
// .logo-accueil-2 {
//   @extend .animated;
//   @extend .fadeInLeft;
//   @extend .img-responsive;
//   @extend .center-block;
//   @extend .text-center;
//   animation-delay: .6s;
//   display: inline-block;
//   margin: 20px auto;
// }
.main-accueil {
@extend .animated;
@extend .fadeIn;
// background-color: rgba($brand-primary, .35);
  // @extend .container-max;
// background: url(../images/charte/bg.png) top center;
min-height: 50vh;
// padding: 7vh 0;
}

.img-intro {
@extend .animated;
@extend .fadeInLeft;
@extend .img-responsive;
@extend .center-block;
// @extend .thumbnail;
// background-color: rgba($body-bg, .5);
// box-shadow: $box-shadow;
// margin: 0 auto 30px auto;
// max-height: 40rem;
// border-top: solid 3px #bebebe;
border: solid 3px $body-bg;
// padding: 1rem 25%;
animation-delay: .7s;
}
// .img-intro-bg {
// background: url(../images/charte/img-intro.jpg) top center;
// background-size: cover;
// padding: 1rem 0;
// }
.delai-001 {
// @extend .img-responsive;
// @extend .center-block;
// @extend .thumbnail;
@extend .animated;
@extend .fadeInRight;
margin-bottom: 1rem;
// background-color: rgba($brand-second, 0.25);
// padding: 20px;
// margin-top: -3rem;
animation-delay: .3s;
//   box-shadow: $box-shadow;
}
.delai-002 {
// @extend .img-responsive;
// @extend .center-block;
// @extend .thumbnail;
@extend .animated;
@extend .fadeInLeft;
margin-bottom: 1rem;
// background-color: rgba($brand-second, 0.25);
// padding: 20px 0;
animation-delay: .5s;
//   box-shadow: $box-shadow;
}
.delai-003 {
// @extend .img-responsive;
// @extend .center-block;
// @extend .thumbnail;
@extend .animated;
@extend .fadeInUp;
// background-color: #fff;
animation-delay: .7s;
// box-shadow: $box-shadow;
}
.delai-004 {
// @extend .img-responsive;
// @extend .center-block;
@extend .animated;
@extend .fadeInUp;
animation-delay: .9s;
// box-shadow: $box-shadow;
}
.delai-005 {
//   @extend .img-responsive;
//   @extend .center-block;
  @extend .animated;
  @extend .fadeInUp;
  animation-delay: 1.3s;
// box-shadow: $box-shadow;
}
.delai-006 {
//   @extend .img-responsive;
//   @extend .center-block;
  @extend .animated;
  @extend .fadeInUp;
  animation-delay: 1.7s;
// box-shadow: $box-shadow;
}
.delai-007 {
//   @extend .img-responsive;
//   @extend .center-block;
  @extend .animated;
  @extend .fadeInUp;
  animation-delay: 1.9s;
// box-shadow: $box-shadow;
}

// .margin-diapo {
//   margin-top: 15vh;
// }


.btn-accueil {
// @extend .animated;
// @extend .fadeInUp;
// animation-delay: .7s;
@extend .btn;
@extend .btn-lg;
@extend .btn-primary;
margin: 5px auto;
// max-height: 14rem;
// padding: 25px 30px;
// font-size: 5rem;
border-radius: $border-radius-large;
// border-radius: $border-radius-large;
// background-color: $text-color !important;
// background-color: darken($brand-second, 5%) !important;
// color: white !important;
// border: solid 3px white !important;
// font-family: 'Kaushan Script', cursive;
// font-weight: 500;
// box-shadow: $box-shadow;
font-size: $font-size-h4;
text-shadow: .1rem .3rem .6rem rgba($brand-primary, .5);
border: solid 3px $brand-second !important;
color: white !important;
border: solid 3px white !important;

&:hover {
background-color: darken($brand-primary, 10%) !important;
border: solid 3px $brand-primary !important;
// color: $brand-primary !important;
transition: transform ease-in .5s;
}

// img {
// margin-top: -7rem;
// }
}

.footer-accueil {

// position: absolute;
// bottom: 0;
// left: 0;
// right: 0;
// @extend .animated;
// @extend .fadeInUp;
@extend .text-center;
@extend .footer;
// margin-top: 20px;
// box-shadow: $box-shadow;
padding: 30px 0;
border-top: solid 3px $brand-second;
position: relative;
z-index: 10 !important;
// border-bottom: solid 3px $brand-second;


// background-color: rgba($body-bg, .95);
// color: $text-color;


h1,.h1 {
// font-size: 35px;
// color: $brand-second !important;
}
}

.col-footer-accueil-left {
@extend .col-sm-6;
@extend .text-left;

@media (max-width: $screen-xs-max) {
text-align: center;
}
}

.col-footer-accueil-right {
@extend .col-sm-6;
@extend .text-right;

@media (max-width: $screen-xs-max) {
text-align: center;
padding-top: 20px;
}
}

.diapo-bandeau {
@extend .animated;
@extend .fadeIn;
animation-delay: .2s;
// background-color: $body-bg;
border-top: solid 3px $brand-second;
border-bottom: solid 3px $brand-second;
// border-left: solid 3px $brand-primary;
// border-right: solid 3px $brand-primary;
position :relative;
 img {
    @extend .img-responsive;
    max-height: 40vh;
 }
}

.texte-primary {
  color: $brand-primary;
}
.texte-second {
  color: $brand-second;
}
.texte-massif {
// @extend .texte-blanc;
// @extend .text-center;
// padding-top: 30px;
font-size: 4rem;
text-shadow: .1rem .3rem .6rem rgba($body-bg, .5);

// color: $brand-second;
// padding: 5px;
}
.un-peu-fat {
  font-size: 2rem;
}
.moins-fat {
font-size: 1.2rem;
}
// .texte-ombre {
//   text-shadow: 1px 3px 12px rgba(#000, 0.75);
// }
// .bande-texte {
//   background: $body-bg;
//   box-shadow: $box-shadow;
//   margin: 1rem 0;
//   padding: 03px 0 0.6rem 0;
// }
// .encart-moche {
//   padding: 20px;
//   margin: 30px;
//   background-color: rgba(#000, 0.25);
//   border: solid 2px $brand-primary;
// }
// .multi-entree {
//   @extend .animated;
//   @extend .fadeIn;
//   @extend .list-inline;
//   @extend .text-center;
//   margin: 6rem auto;
//   animation-delay: .7s;
//   border-top: solid 2px $brand-second;
//   border-bottom: solid 2px $brand-second;
//   background-color: rgba(#000, 0.5);
//   display: flex;
//   flex-flow: row wrap;
//   justify-content: center;
//   align-items: center;
//   >li  {
//     padding: 1rem 5px;
//     width: 22rem;
//     >a {
//     position: relative;
//     font-family: 'Cinzel', serif;
//     font-size: 35px;
//     line-height: 1;
//     transition: all linear 0.5s;
//         color: lighten($brand-second, 5%);
//       &:hover, &:active, &:focus {
//         top: -1px;
//         text-decoration: none;
//         color: $brand-second;
//         text-shadow: 0 0 10px rgba(#fff, 0.5);
//       }
//     }
//   }
// }
// .flexrow {
//   display: flex;
//   flex-flow: row wrap;
//   flex-direction: columns;
//   overflow: hidden;
//   justify-content: center;
//   align-items: center;
//   @media(min-width: $screen-md-min) {
//     flex-direction: row;
//   }
// }
// .card-flex {
//   position: relative;
//   overflow: hidden;
//   width: 100%;
//   max-width: 38rem;
//   >p {
//     background-color: $body-bg;
//     padding: 5px;
//     position: absolute;
//     bottom: 1rem;
//     left: 0;
//     right: 0;
//   }
//   > img {
//     transition: transform ease-out .5s;
//   }
//   &:hover {
//     >img {
//       transform: scale(1.1);
//     }
//   }
//   @media(min-width: $screen-md-min) {
//     width: 20%;
//   }
// }

.bloccueil {
// .bloccueil>.content-bloccueil>.logo-accueil+.side-bloccueil-right
// border: solid 3px $brand-primary;
// @media(max-width: $screen-lg-min) {
margin: 5vh auto;
// max-width: 70%;
// }
// max-width: 70vw;
max-width: 60rem;
// margin: 5vh auto;
overflow: hidden;
position: relative;
z-index: 100;
// border-radius: 6rem 0 6rem 0;
@extend .animated;
@extend .fadeInDown;
@extend .container;
// background-color: $brand-primary;
background: rgba($body-bg, .9);
box-shadow: $box-shadow;
padding: 1rem;

// .content-bloccueil {
//   @extend .clearfix;
//   display: flex;
//   flex-flow: row wrap;
// }

.content-bloccueil {
display: flex;
flex-direction: column;

@media(min-width: $screen-md-min) {
flex-direction: row;
}
}

.side-bloccueil-left {
  .logo-accueil {
    padding-bottom: 1.7rem; 
    margin-bottom: 1.7rem;
    border-bottom: solid 2px $brand-second;
  }
  img {
    margin: auto;
  }
// @extend .col-md-5;
padding: 30px;
@extend .text-center;
width: 100%;

@media(min-width: $screen-md-min) {
width: 65%;
}
}

.side-bloccueil-right {
border-left: solid 3px $brand-second;
overflow: hidden;
// @extend .delai-001;
// @extend .col-md-7;
padding: 0 0 0 1rem;
margin: 0;
// padding: 0;
width: 100%;

@media(min-width: $screen-md-min) {
width: 35%;
}

>img {
@extend .center-block;
@extend .img-responsive;
width: 40%;
@media(min-width: $screen-md-min) {
width: 100%;
}
}
// font-size: $font-size-large;
// color: $brand-second;
}
}


.parallax-window-accueil {
@extend .parallax-window;
display: flex;
flex-flow: row wrap;
justify-content: center;
align-items: center;
min-height: 95vh;
text-align: center;
overflow: hidden;
// background: linear-gradient(45deg, rgba($brand-second, .1) 0%, rgba($brand-second, .1) 100%);
padding: 30vh 25vw;
border-bottom: solid 5px $brand-second;

// &::after{
//   content:'';
//   position: absolute;
//   top:0;
//   left:0;
//   right:0;
//   bottom:0;
//   // background-color: rgba(white, .75);
//   background-color: rgba($gray-darker, .45);
//   // background-color: rgba($brand-primary, .95);
//   // background-color: rgba($brand-second, .5);
// // background: url(../images/charte/BG-section.png) bottom center repeat-x;
//   z-index: 1000;
//   // min-height: 33vh;
// }

>a {
@extend .h1;
font-size: 5rem !important;
display: inline-block;
// text-transform: uppercase;
// font-weight: 700;
color: white;
// color: $brand-primary;
text-shadow: $box-shadow;
position: relative;
transition: all ease-in-out .3s;


>img {
@extend .img-responsive;
max-width: 90%;
margin: 0 auto;
transition: all linear .3s;
}


// &::after {
// content: '';
// position: absolute;
// bottom: -5rem;
// left: 50%;
// width: 0;
// height: .7rem;
// opacity: 0;
// background-color: white;
// transform: translateX(-50%);
// transition: all ease-in-out .3s;
// }

&.wp1::after {
display: none;
}

&:hover::after {
width: 50%;
opacity: 1;
}

@media(min-width: $screen-sm-min) {
font-size: 65px;

&:hover {
// color: red;
font-size: 4.5rem !important;
letter-spacing: 2px;
color: $brand-tierce;
opacity: .95;

>img {
transform: scale(1.3);
}
}
}

@media(min-width: $screen-md-min) {
font-size: 8rem;
}
}
}
/* ==========================================================================
Waypoints
========================================================================== */

.wp1, .wp2, .wp3, .wp4, .wp5, .wp6, .wp7, .wp8 {
// visibility: hidden;
}

.fadeInUpBig {
visibility: visible;
}

// .homeBande1{
//   @extend .animated;
//   @extend .fadeInDown;
//   position: relative;
//   z-index: 2;
//   animation-delay:.2s;
//   background-color: $brand-second;
//   color:#FFF;
//   text-align: center;
//   text-transform: uppercase;
//   letter-spacing: 5px;
//   font-size: 25px;
//   padding: 5px;
//   width:100%;
//   border-radius:0 0 20px 20px;
//   box-shadow: 0px 0px 10px rgba(#000,.5);
// }
// .homeBande2{
//   @extend .animated;
//   @extend .fadeInDown;
//   position: relative;
//   z-index: 1;
//   animation-delay:.7s;
//   background-color: #FFF;
//   color: $brand-second;
//   text-align: center;
//   text-transform: uppercase;
//   font-size: 25px;
//   padding: 5px;
//   width:80%;
//   margin-left: 10%;
//   margin-bottom: 5rem;
//   border-radius:0 0 20px 20px;
//   box-shadow: 0px 0px 10px rgba(#000,.5);
// }