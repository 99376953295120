.maxi-best-of {
    @extend .dropdown;
    position: static !important;
}

.maxi-best-of_dropdown {
    @extend .dropdown-menu;
    @extend .clearfix;
    left: 0;
    right: 0;
    padding: 0;
    border: none;
}

.maxi-best-of_container {
    @extend .container-max;
    @extend .list-unstyled;
    padding: 2%;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;

    ul {
        @extend .list-unstyled;
    }

    a {
        display: block;
        text-transform: none;
        text-shadow: none;
        text-decoration: none;
        // font-size: 1.4rem;

        &:hover {
            background-color: $list-group-hover-bg;
        }
    }

    >li {
        float: left;
        min-width: 25rem;
        margin: .5%;
        background-color: $list-group-bg;
    }

    .category-title {
        font-size: $font-size-base;
        margin: 0;
        padding: 10px;
        color: $gray-darker;
        background-color: $gray-lighter;
        // border-bottom: solid 1px $list-group-border;
    }
}

.maxi-best-of_sub2 {

    >li>a {
        padding: 5px 10px;
        font-size: 1.4rem;
        border-bottom: solid 1px darken($list-group-bg, 2%);
        background-color: $list-group-bg;
    }
}

.maxi-best-of_sub3 {

    >li>a {
        padding: 2px 10px;
        padding-left: 10px;
        font-size: 1.3rem;
        border-bottom: solid 1px darken($list-group-bg, 4%);
        background-color: darken($list-group-bg, 2%);

        &::before {
            content: '-';
            padding-right: 5px;
        }
    }
}

.maxi-best-of_sub4 {

    >li>a {
        padding: 2px 10px;
        padding-left: 15px;
        font-size: 1.3rem;
        border-bottom: solid 1px darken($list-group-bg, 6%);
        background-color: darken($list-group-bg, 4%);

        &::before {
            content: '-';
            padding-right: 5px;
        }
    }
}