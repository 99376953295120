/* ==========================================================================
	 header-basic
	 ========================================================================== */

.header-basic {
	@extend .container-max;
	@extend .text-center;
	margin-top: 3rem;
	position: relative;
	background-color: $body-bg;
  border: solid .1rem $brand-second;

	
	.content-header {
		@extend .container-max;
		padding: 3rem;
	}
	
	.logo-header {
		
		>img {
			@extend .img-responsive;
			display: inline-block;
			max-height: 12rem;
		}
	}
}